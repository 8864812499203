.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 20px 0;
    padding: 16px 0px;
    background-color: #f2f2f2;
  }
  
  .pageItem {
    margin: 0 5px;
    display: inline-block;
    /* width: 20px; */
    
  }
  
  .pageLink {
    display: inline-block;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    min-width: 40px;
  }
  
  .pageLink:hover {
    background-color: #ccc;
  }
  
  .pageLink:focus {
    outline: none;
    box-shadow: 0 0 0 2px #ccc;
  }
  
  .pageLink.active {
    background-color: red;
    color: #fff;
    border: 1px solid #333;
  }
  
  .pageItem.disabled .pageLink {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .pageItem.disabled .pageLink:hover {
    background-color: #fff;
  }
  
  .pageItem:first-child .pageLink {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  
  .pageItem:last-child .pageLink {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .pageItemActive {
    border: 1px solid rebeccapurple;
    background-color: rebeccapurple;
    color: #fff;
    /* background-image: radial-gradient(transparent, transparent, transparent, transparent, rgb(195, 198, 195)); */
  }
  
  
