.listing {
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding: 20px;
  }
  
  .listing__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .listing__title {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 5px 0;
  }
  
  .listing__category {
    font-size: 14px;
    color: #555555;
    margin: 0 0 5px 0;
  }
  
  .listing__price {
    font-size: 18px;
    font-weight: bold;
    color: #00a0e4;
    margin: 0;
  }
  
  .listing__body {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .listing__description {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .listing__geolocation,
  .listing__availability-plan,
  .listing__address {
    width: 50%;
    margin-bottom: 20px;
  }
  
  .listing__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .listing__createdAt {
    font-size: 14px;
    color: #555555;
    margin: 0;
  }
  
  .listing__state {
    font-size: 14px;
    color: #555555;
    margin: 0;
  }
  
  .listing__privateData {
    font-size: 14px;
    color: #555555;
    margin: 0;
    margin-left: auto;
  }
  
  .listing h2, .listing h1{
    margin: 0 0 0px 0;
    color: rebeccapurple;
  }
  .listing p {
    margin: 0 0 5px 0;
  }

.save_button{
  background-color: rebeccapurple;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin: 0 0 20px 0;
}


