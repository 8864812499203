.adminPanelSearchSectionSearchButton, .adminPanelSearchSectionSearchResetButton {
    display: inline-block;
    background-color: #1890ff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
    top: -10px;
    margin-right: 10px;
  }
  
  .adminPanelSearchSectionSearchButton:hover, .adminPanelSearchSectionSearchResetButton:hover {
    background-color: #40a9ff;
    transform: translateY(-2px);
  }