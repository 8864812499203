@import '../../styles/customMediaQueries.css';

.submitButtonContainer {
    width: 100%;

    @media (--viewportLarge) {
        display: flex;
        justify-content: flex-end;
    }
  }
  .submitButton {
    flex-shrink: 0;
  
    @media (--viewportLarge) {
      display: inline-block;
      width: 241px;
    }
  }


  
 