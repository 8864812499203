@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.modalButtons {
  composes: marketplaceModalButtonsStyles from global;
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
}

.modalButton{
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin: 10px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.4s;

  background-color: #007bff;



  &:hover {
    background-color: #0069d9;
  }


}


.confirmButton {
  @extend .button;
  background-color: #28a745;

  &:hover {
    background-color: #218838;
  }
}

.cancelButton {
  @extend .button;
  background-color: #dc3545;

  &:hover {
    background-color: #c82333;
  }
}
