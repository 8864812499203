@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.checkboxes {
  composes: marketplaceModalCheckboxes from global;
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  font-size: 13px;
  line-height: 22px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.checkboxes > div {
  display: flex;
  align-items: flex-start;
  margin: 3px 16px 0 0;
}

.checkboxes input {
  -webkit-appearance: none;
  appearance: none;
  background-color: #f0f0f0;
  border: 1px solid #c0c0c0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
}

.checkboxes input:checked {
  background-color: #0095ff;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><path fill="%23fff" d="M5.6 10.6L1.4 6.4l2-2 2.2 2.1 4.8-4.8 2 2z"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
}

.field {
  composes: marketplaceModalPasswordMargins from global;
}

.neighborhoods {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.checkbox {
  & label {
    margin-top: 8px !important;
  
    @media (--viewportMedium) {
      margin-top: 8px !important;
    }
  }
}

.acceptTermsAndPolicyLabel {
  display: inline;
}
