:root{
    --asc-color: rebeccapurple;
    --desc-color: rebeccapurple;
}

.sortable {
    cursor: pointer;
    white-space: nowrap;
    position: relative;
}
.sortable::after {
    content: "";
    position: absolute;
    margin-left: 8px;
    margin-top: 8px;
    vertical-align: middle;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid;
}
.asc::after {
    transform: rotate(-45deg);
    color: var(--asc-color);
    margin-top: 7px;
}
.desc::after {
    transform: rotate(45deg);
    color: var(--desc-color);
    margin-top: 9px;
}

.sortable.asc{
    color: var(--asc-color);
    text-shadow: black 0 0 0 1px;
}
.sortable.desc{
    color: var(--desc-color);
    text-shadow: black 0 0 0 1px;
}
  
