.adminPanel {
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin: 0 auto;
}

.adminPanel h1 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.adminPanelSearchSection {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.adminPanelSearchSectionLabel {
  font-size: 16px;
  font-weight: 700;
  margin-right: 10px;
}

.adminPanelSearchSectionInput {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
  width: 100%
}

.adminPanelSearchSectionSelect {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
  width: 200px;
}

.adminPanelSearchSectionSearchButton:hover {
  background-color: #40a9ff;
}

.adminPanelSearchSectionTable {
  width: 100%;
  border-collapse: collapse;
}

.adminPanelSearchSectionTable th,
.adminPanelSearchSectionTable td {
  padding: 10px;
  text-align: left;
}

.adminPanelSearchSectionTable th {
  background-color: #f5f5f5;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid #d9d9d9;
}

.adminPanelSearchSectionTable td {
  border-bottom: 1px solid #d9d9d9;
}

.adminPanelSearchSectionTable td:last-child {
  white-space: nowrap;
  text-align: right;
}

.adminPanelSearchSectionTable td button {
  background-color: #fff;
  color: #1890ff;
  border: 1px solid #1890ff;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
}

.adminPanelSearchSectionTable td button:hover {
  background-color: #1890ff;
  color: #fff;
}

.adminPanelSearchSectionTable td button:last-child {
  margin-right: 0;
}

.adminPanelSearchSectionSelect {
  position: relative;
  display: inline-block;
  border-radius: 4px;
  background-color: #f2f2f2;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #555;
  border: none;
  appearance: none;
}

.adminPanelSearchSectionSelect:focus {
  outline: none;
}

.adminPanelSearchSectionSelect:after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-100%);
  font-size: 20px;
  color: #555;
}

.adminPanelSearchSectionSelect option {
  font-weight: normal;
  background-color: #f2f2f2;
  color: #555;
  border-radius: 4px;
  border: none;
}

.adminPanelSearchSectionSelect option:hover {
  background-color: #ddd;
}

.adminPanelSearchSectionSelect option:checked {
  background-color: #2196F3;
  color: #fff;
}

.adminPanelHeaderVisibilty {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.adminPanelHeaderVisibilty label {
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #555;
  display: flex;
  align-items: center;
}

.adminPanelHeaderVisibilty input[type="checkbox"] {
  margin-right: 8px;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.adminPanelHeaderVisibilty input[type="checkbox"]:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 10px;
  height: 10px;
  background-color: #555;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.adminPanelHeaderVisibilty input[type="checkbox"]:checked:before {
  transform: translate(-50%, -50%) scale(1);
}

.flex {
  display: flex;
  align-items: center;
}

.adminPanelSearchSectionNumberInput {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
  width: 100px;
  font-size: 16px;
  font-weight: bold;
  color: #555;
}

.adminPanelSearchSectionNumberInput:focus {
  outline: none;
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.perPageLabel {
  font-size: 16px;
  font-weight: 700;
  margin-right: 10px;
  position: relative;
  top: -12px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #555;
  display: flex;
  align-items: center;
}

.perPageLabelText {
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
  margin-left: 10px;
}

.adminPanelSearchSectionSearchButton, .adminPanelSearchSectionSearchResetButton {
  display: inline-block;
  background-color: #1890ff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  top: -10px;
  margin-right: 10px;
  min-height: 52px;
}

.transactionsButtonWrapper {
  position: relative;
}

.transactionsButton{
  display: inline-block;
  background-color: #1890ff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  top: -10px;
  margin-right: 10px;
  width: 250px;
  min-height: 52px;
}

.transactionsError {
  color: var(--failColor);
  position: absolute;
  z-index: 1;
  display: block;
  left: 260px;
  top: -16px;
  width: 260px;
}

.adminPanelSearchSectionSearchButton {
  font-size: xx-large;
}


.adminPanelSearchSectionSearchButton:hover, .adminPanelSearchSectionSearchResetButton:hover {
  background-color: #40a9ff;
  transform: translateY(-2px);
}